import React, { FC, useCallback, useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MainLayout } from '../../../../templates/MainLayout';
import { IDirectionTagsQuery } from '../../../../queries/directionTags/types';
import { IReviewsQuery } from '../../../../queries/reviews/types';
import { ICaseQuery } from '../../../../queries/cases/types';
import { IHeaderQuery } from '../../../../queries/headers/types';
import { KmmSuccessModal } from '../../../../components/directions/kmm/screens/KmmSuccessModal';
import { CdtoHeader } from '../../../../components/directions/cdto/screens/CdtoHeader';
import { CdtoPros } from '../../../../components/directions/cdto/screens/CdtoPros';
import { CdtoSolutions } from '../../../../components/directions/cdto/screens/CdtoSolutions';
import CtdoOurProjects from '../../../../components/directions/cdto/screens/CtdoOurProjects';
import CdtoHowWeWork from '../../../../components/directions/cdto/screens/CdtoHowWeWork';
import CdtoPrice from '../../../../components/directions/cdto/screens/CdtoPrice';
import { CdtoReviews } from '../../../../components/directions/cdto/screens/CdtoReviews';
import CdtoFaq from '../../../../components/directions/cdto/screens/CdtoFaq';
import { CdtoForm } from '../../../../components/directions/cdto/screens/CdtoForm';
import CdtoOurStack from '../../../../components/directions/cdto/screens/CdtoOurStack';
import { ReCaptchaProvider } from '../../../../components/common/ReCaptchaProvider';

type IProps = PageProps<
  IDirectionTagsQuery & IReviewsQuery & IHeaderQuery & ICaseQuery,
  { locale: string; tag: string; page: number }
>;

const FinancePage: FC<IProps> = ({
  data,
  location,
  pageContext: { locale = 'ru' },
}) => {
  const [isSuccessShown, setIsSuccessShown] = useState(false);
  const reviews = data.reviews.edges;

  const toggleSuccess = useCallback(
    () => setIsSuccessShown(!isSuccessShown),
    [isSuccessShown, setIsSuccessShown]
  );

  const direction = data.directionTags.edges.find((edge) =>
    location.pathname.includes(edge.node.frontmatter.url)
  );

  const title = direction?.node.frontmatter.name ?? '';

  return (
    <ReCaptchaProvider>
      <MainLayout locale={locale} title={title}>
        <CdtoHeader title={title} />
        <CdtoPros />
        <CdtoSolutions />
        <CtdoOurProjects />
        <CdtoHowWeWork />
        <CdtoOurStack />
        <CdtoPrice />
        {reviews && reviews.length > 0 && (
          <CdtoReviews locale={locale} reviews={reviews} />
        )}
        <CdtoFaq />
        <CdtoForm />

        {isSuccessShown && <KmmSuccessModal onClose={toggleSuccess} />}
      </MainLayout>
    </ReCaptchaProvider>
  );
};

export const query = graphql`
  query {
    directionTags: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/directions/tags/" } }
    ) {
      ...DirectionTagsFields
    }

    headers: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/header/header/" } }
    ) {
      ...HeaderFields
    }

    reviews: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/reviews/reviews/" } }
      sort: { fields: fileAbsolutePath, order: DESC }
    ) {
      ...ReviewFields
    }
  }
`;

export default FinancePage;
